import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/main/main.vue";
import XhcMain from "../views/admin/Main.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    component: Main,
	redirect: "/index",
	children: [
		{
	        path: "/index",
			meta:{title:"首页"},
	        component: () => import("../views/home/index.vue")
		},
		{
		    path: "/message",
			meta:{title:"留言板"},
		    component: () => import("../views/message/message.vue"),
		},
		{
		    path: "/link",
			meta:{title:"邻居们"},
		    component: () => import("../views/link/link.vue"),
		},
		{
		    path: "/project",
			meta:{title:"找项目"},
		    component: () => import("../views/project/project.vue"),
		},
		{
		    path: "/chat",
			meta:{title:"群聊"},
		    component: () => import("../views/chat/chat.vue"),
		},
		{
		    path: "/movie",
			meta:{title:"影视剧"},
		    component: () => import("../views/movie/movie.vue"),
		},
		{
		    path: "/movieDetail",
		    component: () => import("../views/movie/detail.vue"),
			meta: { parent:'/movie',title:"影视剧"}
		},
		{
		    path: "/music",
			meta:{title:"听音乐"},
		    component: () => import("../views/music/music.vue"),
		},
		{
		    path: "/harmony",
			meta:{title:"harmony"},
		    component: () => import("../views/harmony/harmony.vue"),
		},
		{
		    path: "/artdetail",
		    component: () => import("../views/article/detail.vue"),
			meta: { parent:'/harmony',title:"文章详情"}
		},
		{
		    path: "/lab",
			meta:{title:"实验室"},
		    component: () => import("../views/lab/lab.vue"),
		},
		{
		    path: "/labdetail",
			meta:{title:"实验室详情", parent:'/lab',},
		    component: () => import("../views/lab/detail.vue"),
		},
		{
		    path: '/404',
		    name: '404',
		    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
			meta: {title:"404"}
		},
	]
  },
  {
    path: "/xhcManage/login",
    name: "xhcLogin",
    component: () =>
      import("../views/admin/Login.vue"),
  },
  {
    path: "/xhcManage/",
    name: "xhcMain",
    component: XhcMain,
    redirect: "/xhcManage/index",
	beforeEnter: checkAuth, // 使用导航守卫验证登录状态
    children: [
    	{
            path: "/xhcManage/index",
            component: () => import("../views/admin/article/index.vue"),
    	},
		{
		    path: "/xhcManage/article",
		    component: () => import("../views/admin/article/index.vue"),
		},
		{
		    path: "/xhcManage/message",
		    component: () => import("../views/admin/message/index.vue"),
		},
		{
		    path: "/xhcManage/user",
		    component: () => import("../views/admin/user/index.vue"),
		},
		{
		    path: "/xhcManage/project",
		    component: () => import("../views/admin/project/index.vue"),
		},
	]
  },
  {
      path: '*',
      redirect: '/404'
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
    if(to.meta.title){//判断是否有标题
        document.title = to.meta.title
    }
    next()
})
function checkAuth(to, from, next) {
  const token = localStorage.getItem('adminInfo');
  if (!token) {
    next('/xhcManage/login');
  } else {
    next();
  }
}
export default router;
