<template>
	<div class="xiao-message" :class="!visible ? 'hide' : ''" v-if="closeStatus">
		<div class="padding-sm item-box crow radius-xs shadow">
			<span class="cuIcon-roundcheckfill icon-scuuess" v-if="type == 'success'"></span>
			<span class="cuIcon-roundclosefill icon-error" v-if="type == 'error'"></span>
			<span class="cuIcon-warnfill icon-warn" v-if="type == 'warn'"></span>
			<span class="cuIcon-loading2 icon-scuuess loading-icon" v-if="type == 'loading'"></span>
			<span class="padding-lr text">{{message}}</span>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return{
			message: '',
			time: 3500,
			visible: false,
			type: 'info', // 'info','warning','error','success'
			closeStatus:false
		}
	},
	watch:{
		visible(e){
			if(e){
				this.closeStatus = true;
			}
		},
	},
	mounted() {
		this.close();
		this.hide();
	},
	methods:{
		close(){
			window.setTimeout(() => {
				this.visible = false
			}, this.time - 800);
		},
		hide(){
			window.setTimeout(() => {
				this.closeStatus = false
			}, this.time);
		}
	}
}
</script>
<style>
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    .loading-icon{
      animation: spin 2s linear infinite; /* 设置动画名称、持续时间、动画速度和重复次数 */
    }
</style>
<style lang="less" scoped>
	.xiao-message{
		position: fixed;
		left: 50%;
		top: 20px;
		z-index: 99999;
		animation: xiao-message-appear .3s ease-in-out forwards;
		transform: translate(-50%);
		display: flex; /* 添加 */
		flex-direction: column-reverse; /* 添加 */
		.item-box{
			// min-width: 90px;
			margin: auto;
			justify-content: center;
			background-color: var(--xiao-message-bg-color);
			backdrop-filter: blur(0.8rem);
			  flex-grow: 1; /* 添加 */
			  margin-bottom: 10px; /* 添加 */
			.icon-scuuess{
				color: var(--xiao-message-success-color);
			}
			.icon-error{
				color: var(--xiao-message-error-color);
			}
			.icon-warn{
				color: var(--xiao-message-warn-color);
			}
			.text{
				font-size: 13px;
			}
		}
	}
	.xiao-message.hide {
	    animation: xiao-message-disappear .3s ease-in-out forwards;
	}
</style>