<template>
	<div class="left-40 menu-box">
		
		<template v-if="currentPage == '/chat'">
			<div class="padding-xs menu-list">
				<div class="text-sm text-center padding-tb-sm">群组成员</div>
				<div class="grid col-4">
					<div class="avatar padding-xs" v-for="(item,index) in memberList" :key="index">
						<img :src="item.avatar ? item.avatar :  require('@/assets/images/logo.png')" alt="" class="radius-xs"/>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="currentPage == '/movie'">
			<div class="padding-xs box">
				<div class="cu-bar search margin-top-xl padding-xs">
					<div class="search-form round">
						<div class="cuIcon-search"></text>
						<input type="search" placeholder="搜索图片、文章、视频" ></input>
						</div>
					</div>
				</div>
				<div class="hister padding-top-xs padding-xs">
					<div class="cu-tag bg-gray radius-xs round">宿命之地</div>
					<div class="cu-tag bg-gray radius-xs round">坠落</div>
				</div>
				<div class="ranks margin-tb-lg">
					<div class="ranks-title text-green margin-tb-xs">
						<i class="cuIcon-titles"></i>
						<span>电影排行</span>
					</div>
					<div class="crow item radius-xs animation-slide-bottom">
						<div class="text-red numbr padding-xs">1</div>
						<div class="thumb padding-xs">
							<img src="http://images.qiumingwx.cn/upload/vod/20231117-1/19f8100d58ca9f9efe2eab491abad56f.jpg" alt="" v-img-error="require('@/assets/images/neetword-error.png')"/>
						</div>
						<div class="info text-sm padding-xs">
							<div class="title">无名之辈2018</div>
							<div class="desc text-gray">陈建斌,任素汐,潘斌龙,章宇,王砚辉,九孔,马吟吟,程怡,宁桓宇,邓恩熙,谢波,范翔,赵梓冲,史策,马睿瀚,林海,邓钢</div>
						</div>
					</div>
					<div class="crow item radius-xs animation-slide-bottom">
						<div class="text-grey numbr padding-xs">2</div>
						<div class="thumb padding-xs">
							<img src="http://images.qiumingwx.cn/upload/vod/20231117-1/63e028f82c87b0c78b0d0e59d83ad0cb.jpg" alt="" v-img-error="require('@/assets/images/neetword-error.png')"/>
						</div>
						<div class="info text-sm padding-xs">
							<div class="title">兄弟班（2018）</div>
							<div class="desc text-gray">陈家乐,于湉,王梓轩,吴鹤谦,林耀声,邓加乐,任达华,金燕玲,惠英红,廖启智,谭咏麟,钟镇涛,钱小豪,陈友,彭健新,叶智强</div>
						</div>
					</div>
					<div class="crow item radius-xs animation-slide-bottom">
						<div class="text-orange numbr padding-xs">3</div>
						<div class="thumb padding-xs">
							<img src="http://images.qiumingwx.cn/upload/vod/20231117-1/8d7910ccfe0a56a76e5d8b7bc0cd8ede.jpg" alt="" v-img-error="require('@/assets/images/neetword-error.png')"/>
						</div>
						<div class="info text-sm padding-xs">
							<div class="title">我的青春有点二</div>
							<div class="desc text-gray">吴宣萱,俸泉,胡之熙,陈文俊,任格漪</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="currentPage == '/music'">
			<!-- <div class="padding-xs menu-list">
				<div class="text-sm text-center padding-tb-sm">
					<span class="cuIcon-sort"></span>
					歌曲列表
				</div>
				<div class="flex text-sm padding-lr-xs padding-tb-sm" v-for="(item,index) in music" :key="index">
					<a class="flex-twice" href="javascript:" @click="changeMusic(item)">
						<span class="padding-right-xs">{{index + 1}}.</span>
						{{item.name}}
					</a>
					<div class="flex-sub text-right text-gray">{{item.auth}}</div>
				</div>
			</div> -->
			<MusicRight @musicChange="musicChange"/>
		</template>
		<template v-else>
			<div class="padding-xs text-center menu-list">
				<div class="cu-bar search margin-top-xl padding-xs">
					<div class="search-form round">
						<div class="cuIcon-search"></text>
							<input type="search" placeholder="搜索图片、文章、视频" ></input>
						</div>
					</div>
				</div>
				<div class="padding-xs margin-top-lg">
					<div class="text-sm text-left padding-tb-sm">
						<i class="cuIcon-friendfill"></i>
						最近访问
					</div>
					<div class="grid col-5">
						<div class="avatar padding-xs" v-for="(item,index) in recentlyList" :key="index">
							<img :src="item.userInfo.avatar_url ? item.userInfo.avatar_url : require('@/assets/images/logo.png')" alt="" class="radius-xs"/>
						</div>
					</div>
				</div>
				<div class="art-rank-list padding-xs">
					<div class="text-sm text-left padding-top-sm">
						<i class="cuIcon-rank"></i>
						文章排行
					</div>
					<div 
						:class="artRank.length - 1 != index ? 'solid-bottom' : ''"
						class="lists crow padding-tb-sm" 
						v-for="(item,index) in artRank" :key="index">
						<img :src="item.image_url" alt="" class="radius-xs"/>
						<div class="right-box margin-left-xs" @click="toArtDetail(item.article_id)">
							<div class="title text-left">
								<a href="javascript:">{{item.title}}</a>
							</div>
							<div class="crow text-gray">
								<div class="text-sm text-left padding-top-xs">
									<i class="iconfont icon-xianshi"></i>
									<span class="padding-left-mn">{{item.view}}</span>
								</div>
								<div class="text-sm text-left padding-top-xs padding-left-sm">
									<i class="iconfont icon-zan1"></i>
									<span class="padding-left-mn">{{item.zan}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		
		
	</div>
</template>

<script>
	import MusicRight from './right/music.vue';
	export default {
		components: {
		  MusicRight
		},
		props:{
			currentPage: {
				type: String,
				default: '',
			}
		},
	  data(){
		  return{
			  recentlyList:[],
			  artRank:[],
				memberList:[
					{
						avatar:'https://p.qqan.com/up/2023-9/20239289931891.jpg'
					},
					{
						avatar:'https://p.qqan.com/up/2023-9/202391994255140.jpg'
					},
					{
						avatar:'https://p.qqan.com/up/2023-9/2023915836181913.jpg'
					},
					{
						avatar:'https://p.qqan.com/up/2023-9/2023929911506306.jpg'
					},
					{
						avatar:'https://p.qqan.com/up/2023-10/20231026751152482.jpg'
					}
				],
		  }
	  },
	  mounted () {
		
	  },
	  created() {
		  // this.$emit('musicChange',{data:this.music[0]})
		  this.getrecently();
	  },
	  methods:{
		  getrecently(){
			  this.$http.recently().then(res => {
				 this.recentlyList = res.data.list.data;
				 this.artRank = res.data.rank;
			  })
		  },

		  closeModal(){
			  
		  },
			musicChange(e){
				this.$emit('musicChange',{data:e.data})
			},
			toArtDetail(id){
				this.$router.push('/artdetail?id='+id)
			}
	  }
	};
</script>

<style lang="less" scoped>
// .menu-box{
// 	width: 230px;
// 	float: left;
// 	height: 100vh;
// 	.menu-list{
// 		background-color: rgba(255, 255, 255, .7);
// 		width: 230px;
// 		position: fixed;
// 		margin-top: 30px;
// 		height: calc(90% + 20px);
// 		border-bottom-right-radius: 20px;
// 		border-top-right-radius: 20px;
// 		backdrop-filter: blur(0.2rem);
// 	}
// }

.avatar{
	box-sizing: border-box;
	img{
		width: 100%;
	}
}
.search{
	input{
		border: none;
	}
}

// movie
.ranks{
	cursor: pointer;
	.item{
		padding: 5px;
		transition: box-shadow 0.3s;
		.ranks-title{
			// color: gray;
		}
		.numbr{
			font-style: italic;
		}
		.thumb{
			img{
				width: 40px;
				height: 50px;
				border-radius: 4px;
			}
		}
		.info{
			.title{
				padding-bottom: 4px;
			}
			.desc{
				font-size: 12px !important;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
		}
	}
	.item:hover{
		box-shadow: 0px 0px 15px #cccccc;
	}
}
// 文章排行
.art-rank-list{
	.lists{
		img{
			width: 22%;
			height: 47px;
		}
		.right-box{
			width: 77%;
			.title{
				font-size: 12px;
			}
		}
		
	}
}
</style>