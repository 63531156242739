const state = {
  userInfo: null,
  imUserInfo:null
}

const mutations = {
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo
  },
  SET_IM_USER_INFO(state, userInfo) {
    state.imUserInfo = userInfo
  },
}

const actions = {
  setUserInfo({ commit }, userInfo) {
    commit('SET_USER_INFO', userInfo)
  },
  setIMuserInfo({ commit }, userInfo){
	  console.log('====================================='+userInfo)
	commit('SET_IM_USER_INFO', userInfo)
  },
  loadUserInfoFromLocalStorage({ commit }) {
    const token = localStorage.getItem('token')
    const user = JSON.parse(localStorage.getItem('userInfo'))

    if (token && user) {
      commit('SET_USER_INFO', { token, user })
    }
  },
  logout({ commit }) {
      commit('SET_USER_INFO', null);
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
	}
}

const getters = {
  userInfo: state => state.userInfo,
  imUserInfo: state => state.imUserInfo
}

export default {
  state,
  mutations,
  actions,
  getters
}
