<template>
	<div class="left-40 menu-box">
		<div class="padding text-center menu-list">
			<div class="logo margin-tb">
				<template  v-if="!user">
					<img src="@/assets/images/logo.png" alt="" class="round">
					<div class="text-sm margin-left-xs">闲时光</div>
				</template>
				<template  v-else>
					<img :src="user.avatar_url ? user.avatar_url : require('@/assets/images/logo.png')" alt="" class="round">
					<span class="text-sm margin-left-xs">{{user.nick_name?user.nick_name:'微信好友'}}</span>
				</template>
			</div>
			<!-- activeText -->
			<a 	class="item crow padding-tb-xs text-center" 
				:class="avtiveMenu == item.pages ? 'text-green' : ''"
				href="javascript:" 
				@click="toUrl(item.pages)"
				v-for="(item,index) in menuList" 
				:key="index">
				<i class="iconfont menu-icon" :class="item.icon"></i>
				<span class="margin-left">{{item.name}}</span>
			</a>
			<!-- 登录按钮 -->
			<div class="login-button-box">
				<button class="cu-btn bg-green shadow shadow-blur radius-xs" @click="toLogin()" v-if="!user">
					<span class="padding-lr">
						<i class="iconfont icon-wode1 padding-right-xs"></i>
						登录
					</span>
				</button>
				<button class="cu-btn bg-red shadow shadow-blur radius-xs" @click="logout()" v-if="user">
					<span class="padding-lr">
						<i class="iconfont icon-guanbi1 padding-right-xs"></i>
						退出
					</span>
				</button>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	
	import { mapGetters } from 'vuex';
	export default {
		props:{
			avtiveMenu: {
				type: String,
				default: '',
			}
		},
		computed: {
		    ...mapGetters(['userInfo']),
		    user() {
		      return this.userInfo ? this.userInfo.user : null;
		    }
		},
	  data(){
		  return{
			menuList:[
				{
					name:'生活圈',
					icon:'icon-shouye',
					pages:'/index'
				},
				{
					name:'找项目',
					icon:'icon-fenlei1',
					pages:'/project'
				},
				{
					name:'Harmony',
					icon:'icon-HarmonyOS',
					pages:'/harmony'
				},
				{
					name:'实验室',
					icon:'icon-ceshi',
					pages:'/lab'
				},
				// {
				// 	name:'聊天室',
				// 	icon:'icon-pintuan',
				// 	pages:'/chat'
				// },
				{
					name:'留言板',
					icon:'icon-pinglun1',
					pages:'/message'
				},
				{
					name:'邻居们',
					icon:'icon-lianjie',
					pages:'/link'
				},
				// {
				// 	name:'影视剧',
				// 	icon:'icon-yingshi',
				// 	pages:'/movie'
				// },
				{
					name:'听音乐',
					icon:'icon-yinle1',
					pages:'/music'
				},
			],
		  }
	  },
	  methods:{
		 toUrl(url){
			if(url == this.$route.path){
				 return;
			}
			this.$router.push(url)
		 },
		 toLogin(){
			this.$emit('toLogin',{data:true})
		 },
		 logout(){
			this.$emit('logout',{data:true})
		 }
	  }
	};
</script>

<style lang="less" scoped>
// .menu-box{
// 	// width: 260px;
// 	// height: 100vh;
// 	// float: right;
// 	// position: relative;
// 	.menu-list{
// 		background-color: rgba(255, 255, 255, .7);
// 		width: 230px;
// 		position: fixed;
// 		margin-top: 30px;
// 		height: 90%;
// 		border-top-left-radius: 20px;
// 		border-bottom-left-radius: 20px;
// 		backdrop-filter: blur(0.2rem);
// 	}
// }
.logo{
	width: 100%;
	margin: auto;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	img{
		width: 20px;
		height: 20px;
	}
}
	
.item{
	width: 100px;
	margin:20px auto;
	position: relative;
	// color: #666666;
	.activeImg{
		width: 120px;
		height: 10px;
		position: absolute;
		top: 70%;
		left: 5%;
	}
	.iconfont{
		font-size: 17px;
	}
}
.activeText{
	color: orange;
}
.login-button-box{
	position: absolute;
	bottom: 20px;
	width: 100%;
	left: 0;
}
</style>