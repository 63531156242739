let apiList = {
    homeData: ["get", "Index/homeData"],
    musicList: ["get", "Music/list"],
    loginQrcode: ["get", "Login/getQrcode"],
    loginQrcodeStatus: ["post", "Login/getScanStatus"],
    addMessage: ["post", "Message/add"],
    messageList: ["get", "Message/list"],
	ArticleList: ["get", "Article/list"],
	ArticleInfoById: ["get", "Article/info"],
	recently: ["get", "Index/recently"],
	DebugApi: ["post", "api.Debug/send"],
	ApiList: ["get", "api.ApiList/list"],
	ApiInfo: ["get", "api.ApiList/info"],
	lockInfoByPwd: ["post", "Article/lockInfoByPwd"],
	xhcUserList: ["get", "xhcManage.User/list"],//后台
	saveArticle: ["post", "xhcManage.Article/save"],//后台
	listArticle: ["get", "xhcManage.Article/list"],//后台
	listMessage: ["get", "xhcManage.Message/list"],//后台
	updateMessage: ["post", "xhcManage.Message/update"],//后台
	xhcLogin: ["post", "xhcManage.Login/login"],//后台
	xhcLogout: ["post", "xhcManage.Login/logout"],//后台
	fileGroup: ["get", "xhcManage.Upload/group"],//文件分组
	fileList: ["get", "xhcManage.Upload/list"],//文件列表
	cateList: ["get", "xhcManage.Category/list"],//分类列表
	saveProject: ["post", "xhcManage.Project/save"],//保存项目
	projectList: ["get", "xhcManage.Project/list"],//项目列表
	projectInfo: ["get", "xhcManage.Project/info"],//项目详情
}

export default apiList;
