import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 消息提醒
import Message from '@/components/uimodules/message';
Vue.prototype.$xmessage = Message.install;

// 分割线
import Divider from '@/components/uimodules/divider';
Vue.component('Divider', Divider);

// 请求
import http from '@/api/http'; // 引入接口列表
Vue.prototype.$http = http; // 将接口列表挂载到全局
import 'nprogress/nprogress.css'

//腾讯Tim
// import TencentCloudChat from '@tencentcloud/chat';
// let options = {
//   SDKAppID: 1400612182
// };
// let Chat = TencentCloudChat.create(options);
// Chat.setLogLevel(0);
// Vue.prototype.$Chat = Chat;
// Vue.prototype.$TencentCloudChat = TencentCloudChat;

// element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


Vue.directive('img-error', {
  inserted(el, binding) {
    el.addEventListener('error', () => {
      el.src = binding.value;
    });
  },
  update(el, binding) {
    if (el.src !== binding.value) {
      el.src = binding.value;
    }
  }
});

import './assets/font/uucn/usuuu.css';
import './assets/css/main.css';
import './assets/css/icon.css';
import './assets/css/app.css';
import './assets/icon/iconfont.css';
import 'highlight.js/styles/atom-one-dark.css'  //导入代码高亮样式

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
	// created() {
 //      window.addEventListener('resize', this.handleResize);
	//   window.addEventListener('keydown', this.handleKeyDown);
 //    },
 //    destroyed() {
 //      window.removeEventListener('resize', this.handleResize);
	//   window.removeEventListener('keydown', this.handleKeyDown);
 //    },
 //    methods: {
 //      handleResize() {
 //        if (window.outerWidth - window.innerWidth > 100) {
	// 		this.$router.push('/404'); // 如果检测到开发者工具打开，跳转到 404 页面
 //        }
 //      },
	//   handleKeyDown(event) {
	// 	if (event.keyCode === 123) {
	// 	  this.$router.push('/404'); // 如果检测到用户按下 F12 键，跳转到 404 页面
	// 	}
	//   }
	// }
}).$mount("#app");
