<template>
	<div class="content">
		<template v-if="!includes()">
			<!-- <div class="header crow space-between" id="topNav" :style="topNavBg" v-if="topNavBg.backgroundColor && topNavBg.backgroundColor !='transparent'">
				<div class="left-box padding-left">
					<div class="search round bg-white padding-lr">
						<i class="iconfont icon-sousuo1"></i>
						<input type="search" class="round"/>
					</div>
				</div>
				<div class="right-box">
					1
				</div>
			</div> -->
			<div class="container2">
				<div class="circle">
					<img src="https://img.yingmapi.cn/yingm_api/bg/IMG_0262.png" alt="" />
					<div class="userinfo margin-right">
						<div class="top crow space-between">
							<div class="nickname text-white margin-bottom">
								拍拍你的名字
							</div>
							<div class="avatar margin-left">
								<img src="https://img.yingmapi.cn/yingm_api/bg/IMG_0261.jpg" alt="" class="radius-lg"/>
							</div>
						</div>
						<div class="sign-text padding-top-xs text-sm">
							立志做个富公主👸，终身浪漫，去追梦，寻自由
						</div>
					</div>
				</div>
				
				<div class="justify-around">
					
				</div>
			</div>
		</template>
		<RouterView :musicData="musicData"/>
	</div>
</template>

<script>
	export default {
		props:{
			currentPage: {
				type: String,
				default: '',
			},
			musicData:{
				type:[Object,Array],
				default:()=>[],
			}
		},
	  data(){
		  return{
			topNavBg: {
				backgroundColor: ''
			},
		  }
	  },
	  mounted () {
			// window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
	  },
	  created() {
	  	
	  },
	  methods:{
		  includes(){
			  let whiteList = [
				  '/movieDetail','/chat','/movie','/music','/artdetail','/labdetail'
			  ];
			  return whiteList.includes(this.currentPage);
		  },
		 // 滚动页面时触发导航变色
		handleScroll () {
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			// 设置背景颜色的透明度
			if (scrollTop) {
					this.topNavBg.backgroundColor = `rgba(235, 242, 237,
					${scrollTop / (scrollTop + 60)})` // scrollTop + 多少根据自己的需求设置
			} else if (scrollTop === 0) {
				this.topNavBg.backgroundColor = 'transparent' // 设置回到顶部时，背景颜色为透明
			}
		},
		// 滚动之前重置
		beforeDestroy () {
			window.removeEventListener('scroll', this.handleScroll)
		},
	  }
	};
</script>
<style>
	@import "@/assets/css/animation.css";
</style>
<style lang="less" scoped>
	.content {
		min-height: calc(90vh + 30px);
		background-color: white;
	  .header{
		  width: 40%;
		  height: 60px;
		  position: fixed;
		  z-index: 9999;
		  backdrop-filter: blur(0.2rem);
		  background-color: #ebf2edab;
		  border-radius: 0;
		  transition: 100ms;
		  animation: xiao-nav-fadenum 1s 1;
		  .left-box{
			  .search{
				  width: 200px;
				  input{
					  padding: 10px;
					  border: none;
					  outline:none;
				  }
			  }
		  }
	  }
	  
	}
	
	.circle{
		position: relative;
		margin-bottom: 40px;
		img{
			width: 100%;
			height: 270px;
			object-fit: cover;
		}
		.userinfo{
			position: absolute;
			bottom: -14%;
			right: 0;
			.top{
				justify-content: flex-end;
				.avatar{
					width: 75px;
					height: 75px;
					img{
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
</style>