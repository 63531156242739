<template>
	<div class="padding-xs menu-list">
		<div class="text-sm text-center padding-tb-sm">
			<span class="cuIcon-sort"></span>
			歌曲列表
		</div>
		<div class="flex text-sm padding-lr-xs padding-tb-sm" v-for="(item,index) in music" :key="index">
			<a class="flex-twice" href="javascript:" @click="changeMusic(item)">
				<span class="padding-right-xs">{{index + 1}}.</span>
				{{item.title}}
			</a>
			<div class="flex-sub text-right text-gray">{{item.auther}}</div>
		</div>
	</div>
</template>

<script>
	export default {
	  data(){
		  return{
				music:[],
		  }
	  },
	  created() {
		  let self = this;
		  self.getMusic();
	  },
	  methods:{
		  getMusic(){
			  this.$http.musicList({}).then(res => {
				this.music = res.data.list.data;
				this.$emit('musicChange',{data:res.data.list.data[0]})
			  })
		  },
		changeMusic(item){
			this.$emit('musicChange',{data:item})
		}
	  }
	};
</script>

<style lang="less" scoped>
// .menu-list{
// 	background-color: rgba(255, 255, 255, .7);
// 	width: 230px;
// 	position: fixed;
// 	margin-top: 30px;
// 	height: calc(90% + 20px);
// 	border-bottom-right-radius: 20px;
// 	border-top-right-radius: 20px;
// 	backdrop-filter: blur(0.2rem);
// }
</style>