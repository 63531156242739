<template>
	<div>
		<div class="crow container">
			<div class="main-left-box">
				<LeftMenu 
					:currentPage="currentPage" 
					:avtiveMenu="avtiveMenu" 
					@toLogin="toLogin"
					@logout="logout"/>
			</div>
			<div class="container-box">
				<Container :currentPage="currentPage" :musicData="musicData"/>
			</div>
			<div class="main-right-box">
				<RightInfo :currentPage="currentPage" @musicChange="musicChange"/>
			</div>
		</div>
		
		<!-- 登录框 -->
		<Modal :show="loginData.show">
			<div class="padding login-box">
				<div class="top crow">
					<img src="@/assets/images/wechat-icon.png" alt="" />
					<div class="padding-left-sm">微信扫码登录或注册</div>
				</div>
				<div class="qrcode-box">
					<img :src="loginData.qrcode" alt="" />
				</div>
				<div class="foot text-gray text-sm padding-top text-center">
					打开微信扫一扫，快速登录/注册
				</div>
				
				<div class="text-center margin-top">
					<button class="cu-btn bg-red shadow shadow-blur radius-xs round" @click="closeLogin()">
						关闭
					</button>
				</div>
			</div>
		</Modal>

	</div>
</template>

<script>
	import LeftMenu from '@/components/layout/leftMenu.vue';
	import RightInfo from '@/components/layout/rightInfo.vue';
	import Container from '@/components/layout/container.vue';
	import Modal from '@/components/uimodules/modal';
	export default {
	  components: {
	    LeftMenu,RightInfo,Container,Modal
	  },
	  data(){
		  return{
			  currentPage:'',
			  avtiveMenu:'',
			  musicData:[],
			  loginData:{
			  	show:false,
			  	qrcode:'',
			  	token:''
			  },
			  LoginIntervalId: null,
		  }
	  },
	  mounted () {
		 this.handlerRoute()
	  },
	  created() {
		this.$store.dispatch('loadUserInfoFromLocalStorage'); // 组件创建时调用更新用户信息的 action
	  },
	  methods:{
		  musicChange(e){
			  this.musicData = e.data;
		  },
		handlerRoute(){
			let self = this;
			this.$watch('$route', (to, from) => {
				if(to.meta.parent){
					self.avtiveMenu = to.meta.parent;
				}else{
					self.avtiveMenu = to.path;
				}
				self.currentPage = to.path;
			}, { immediate: true });
		},
		
		toLogin(){
			this.$http.loginQrcode({}).then(res => {
				if(res.status == 1){
					this.loginData = {
						show:true,
						qrcode:res.data.qrcode,
						token:res.data.tokenStr
					}
					this.QrcodeStatusInterval();
				}
			})
		},
		closeLogin(){
			this.loginData = {
				 show:false,
				 qrcode:'',
				 token:''
			}
			clearInterval(this.LoginIntervalId);
		},
		QrcodeStatusInterval(){
			this.LoginIntervalId = setInterval(() => {
			   this.getQRCodeStatus();
			}, 2000);
		},
		getQRCodeStatus(){
			this.$http.loginQrcodeStatus({token:this.loginData.token}).then(res => {
				if(res.data.result == 4){
					clearInterval(this.LoginIntervalId);
					this.toLogin();
				}else if(res.data.result == 3){
					this.$store.dispatch('setUserInfo', res.data.userInfo);
					localStorage.setItem('token',res.data.userInfo.token);
					localStorage.setItem('userInfo',JSON.stringify(res.data.userInfo.user));
					this.closeLogin();
					this.$xmessage({
					  type: 'success',
					  message: '登录成功'
					});
				}
			})
		},
		logout() {
			this.$store.dispatch('logout').then(() => {});
		},
	  }
	}
</script>

<style>
body{
	background-image: url(@/assets/images/background.png);
	background-repeat: no-repeat;
	background-size: 100%;
	background-attachment: fixed; /* 将背景固定在视口中 */
	/* background-color: var(--xiao-page-color); */
}
.container{
	margin: auto;
	width: 100%;
	height: 100vh;
	padding: 30px 200px;
	box-sizing: border-box;
}
.main-left-box{
	width: 20%;
	height: 100%;
	border-bottom-left-radius: 15px;
	border-top-left-radius: 15px;
	background-color: rgba(255, 255, 255, .7);
	backdrop-filter: blur(0.2rem);
}
.container-box{
	width: 60%;
	height: 100%;
	background-color: white;
	overflow: auto;
}
.main-right-box{
	width: 20%;
	height: 100%;
	border-bottom-right-radius: 15px;
	border-top-right-radius: 15px;
	background-color: rgba(255, 255, 255, .7);
	backdrop-filter: blur(0.2rem);
	z-index: 9;
}
.padding{
	box-sizing: border-box;
}

/* 登录 */
.login-box{
	width: 300px;
}
.login-box .top{
	margin: auto;
	width: 175px;
}
.login-box .top img{
	width: 20px;
	height: 20px;
}
.login-box .qrcode-box{
	width: 140px;
	height: 140px;
	margin: auto;
	text-align: center;
	margin-top: 20px;
	border: 1px solid #cccccc;
	border-radius: 5px;
	
}
.login-box .qrcode-box img{
	width: 100%;
	border-radius: 5px;
}
</style>