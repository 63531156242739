<template>
	<div class="admin-container">
		<div class="admin-main-left-box">
			<div class="logo text-center">
				<img src="@/assets/images/admin/logo.png" alt=""/>
			</div>
			<div class="menu-box list">
				<a 	
					:class="avtiveMenu == item.pages ? 'active' : ''"
					v-for="(item,index) in menuList" :key="index"
					class="item crow padding-sm radius-xs margin-bottom" 
					href="javascript:"
					@click="toUrl(item.pages)">
					<i class="iconfont menu-icon padding-left" :class="item.icon"></i>
					<span class="margin-left-xl">{{item.name}}</span>
				</a>
			</div>
		</div>
		<div class="admin-container-box">
			<div class="header-box padding">
				<div class="crow space-between">
					<div>早上好-{{adminInfo.real_name}}</div>
					<div class="right-box padding-right">
						<div class="admin-info crow">
							<img src="@/assets/images/admin/avatar.png" alt="" class="round"/>
							<div class="padding-left-xs">{{adminInfo.user_name}}</div>
							<div class="logout margin-left">
								<button class="cu-btn bg-red shadow shadow-blur sm radius-sm" @click="logout()">
									<i class="cuIcon-pullright"></i>
									<span class="padding-left-mn">退出</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="admin-container-center">
				<RouterView />
			</div>
		</div>
	
		
		<!-- 退出登录确认框 -->
		<Modal :show="LogoutModalShow" @closeModal="closeModal" :showFoot="true">
			<div class="text-center">
				<span>确定要退出吗？</span>
			</div>
		</Modal>
		
	</div>
</template>

<script>
	import Modal from '@/components/uimodules/modal';
	export default {
		components:{
			Modal
		},
	  data(){
		  return{
		    adminInfo:JSON.parse(localStorage.getItem('adminInfo')),
			LogoutModalShow:false,
			avtiveMenu:'',
			menuList:[
				{
					name:'首页',
					icon:'icon-paihangbang',
					pages:'/xhcManage/index'
				},
				{
					name:'留言',
					icon:'icon-pinglun1',
					pages:'/xhcManage/message'
				},
				{
					name:'文章',
					icon:'icon-dingdan',
					pages:'/xhcManage/article'
				},
				{
					name:'项目',
					icon:'icon-moban',
					pages:'/xhcManage/project'
				},
				{
					name:'动态',
					icon:'icon-faxian',
					pages:'/project'
				},
				{
					name:'会员',
					icon:'icon-pintuan',
					pages:'/xhcManage/user'
				},
				{
					name:'管理',
					icon:'icon-wode2',
					pages:'/project'
				}
			],
		  }
	  },
	  mounted () {
		 this.handlerRoute()
	  },
	  methods:{
		 toUrl(url){
			if(url == this.$route.path){
				 return;
			}
			this.$router.push(url)
		 },
		 handlerRoute(){
		 	let self = this;
		 	this.$watch('$route', (to, from) => {
		 		if(to.meta.parent){
		 			self.avtiveMenu = to.meta.parent;
		 		}else{
		 			self.avtiveMenu = to.path;
		 		}
		 		self.currentPage = to.path;
		 	}, { immediate: true });
		 },
		 logout(){		
			 this.LogoutModalShow = true;			 
		 },
		 closeModal(e){
			if(e.type == "confirm"){
				this.$http.xhcLogout().then(res => {
					localStorage.removeItem('adminInfo');
					this.$router.push('/xhcManage/login')
				})
			}else{
				this.LogoutModalShow = false;
			}
		 }
	  }
	};
</script>

<style lang="less" scoped>
	div{
		box-sizing: border-box;
	}
	.admin-container{
		.admin-main-left-box{
			width: 15%;
			height: 100vh;
			background-color: white;
			position:fixed;
			.logo{
				width: 100%;
				height: 100px;
				display: flex;
				align-items: center;
				justify-content: center;
				img{
					width: 100px;
				}
			}
			.menu-box{
				.item{
					width: 80%;
					margin:10px auto;
					i{
						color: #000000;
						font-size: 17px;
					}
					span{
						color: #3F3F44;
						font-size: 15px;
					}
				}
				.active{
					background-color: #CCEABB;
				}
			}
		}
		.admin-container-box{
			width: 85%;
			float: right;
			.header-box{
				width: 85%;
				position: fixed;
				background-color: white;
				z-index: 99;
				.right-box{
					.admin-info{
						img{
							width: 30px;
							height: 30px;
						}
					}
				}
			}
			.admin-container-center{
				padding-top: 58px;
			}
		}
	}
</style>