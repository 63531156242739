import axios from 'axios';
import router from '@/router/index'
import apiList from './apiList';
import webroot from './config';
// import store from '@/store';

import nProgress from "nprogress";

import Message from '@/components/uimodules/message';
const $xmessage = Message.install;

axios.defaults.timeout = 30000; // 超时终止请求
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT; // 配置请求地址
axios.defaults.baseURL = webroot.api; // 配置请求地址
// axios.defaults.withCredentials = true;

const toLogin = () => {
    router.push({
        path: '/login',
        query: {
            redirect: router.currentRoute.fullPath
        }
    })
}
let token = '';
// 设置请求拦截器
axios.interceptors.request.use(
  config => {
    config.data = JSON.stringify(config.data);
    config.headers = {
        'Content-Type': 'application/json;charset=UTF-8',
		'Access-Token': localStorage.getItem('token') ? localStorage.getItem('token') : token,
		'M-Token': localStorage.getItem('adminInfo') ? JSON.parse(localStorage.getItem('adminInfo')).token : ''
    }
	nProgress.start();
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 设置响应拦截器
axios.interceptors.response.use(
  response => {
    let res = response.data; // 取到响应返回的数据
	nProgress.done();
	if(res.status == 0){
		$xmessage({
			type: 'error',
			message: res.message
		});
		return;
	}
    if(res.status == 500) {
		$xmessage({
		  type: 'warn',
		  message: '网络连接失败，请稍后再试'
		});
       return;
    }else if(res.status == 301) {
		$xmessage({
		  type: 'warn',
		  message: '请先登录'
		});
       return;
    }else {
      return res;
    }
  },
  error =>{
	  $xmessage({
	    type: 'warn',
	    message: '网络连接失败，请稍后再试'
	  });
    // Message.error(error.response.data.msg);
    // if(error.response.data.error_code===30000 || error.response.data.error_code===30001){
    //     setTimeout(function()  {
    //         location.reload();
    //     }, 1500);
    // }
    // if(error.response.data.error_code===301301){
    //     setTimeout(function()  {
    //       localStorage.removeItem("userinfo");
    //         location.href = '/';
    //     }, 1500);
    // }
    // if(error.response.data.error_code===10001){
    //   localStorage.clear();
    //   location.href = '/';
    // }
    // return Promise.reject(error);

  }
)

// 以下是封装方法：
// get delete请求方式中，第一个参数为请求的url地址，第二个参数为请求的一些配置项，需要传递给后端的参数包含在配置项的data或者params属性中，
// post put patch请求则第一个参数为url地址，第二个参数是需要入参的json数据，第三个参数是入参以外的其他配置项。

var list = {};

for(let item in apiList) {
  list[item] = function(params) {
    let [method, url] = apiList[item];
    let data = (method == 'get' || method == "delete") ? {params} : (method == 'post' || method == "put" || method == "patch") ? params : {}
    return new Promise((resolve, reject) => {
      axios[method](url, data)
      .then(res => {
        if(res) {
          // 在响应拦截器中，设置了code非200不返回数据，res则有可能会是undefined。所以判断res有数据才用resolve送出去。
          resolve(res);
        }
      })
      .catch(err => {
        reject(err);
      })
    })
  }
}

export default list;
