<template>
	<div class="xiao-modal" v-if="show">
	  <div class="modal-content">
	    <div class="modal-slot">
	      <slot></slot>
	    </div>
	    <div class="modal-footer" v-if="showFoot">
	      <div class="modal-btns">
	        <button class="modal-close" @click="colse()">关闭</button>
	        <button class="modal-ok bg-green cu-btn" @click="confirm()">确认</button>
	      </div>
	    </div>
	  </div>
	  <div class="modal-mask"></div>
	</div>
</template>

<script>
	export default {
		props:{
			show: {
				type: Boolean,
				default: false,
			},
			showFoot:{
				type: Boolean,
				default: false,
			}
		},
		data() {
			return {
				
			};
		},
		methods:{
			colse(){
				this.$emit('closeModal',{type:'close',data:[]});
			},
			confirm(){
				this.$emit('closeModal',{type:'confirm',data:[]});
			}
		}
	}
</script>

<style lang="less" scoped>
	.xiao-modal{
		// backdrop-filter: blur(0.2rem);
		.modal-content {
		    z-index: 999;
		    background-color: var(--xiao-modal-bg-color);
		    width: auto;
		    height: auto;
		    display: flex;
		    flex-direction: column;
		    justify-content: space-between;
		    border: none;
		    border-radius: 4px;
		    position: fixed;
		    top: 40%;
		    left: 50%;
		    transform: translate(-50%,-40%);
		    transition: all .5s ease;
		    animation: modalAnimation .3s;
			.modal-slot {
			    padding: 12px;
			}
			.modal-header {
			    width: 100%;
			    display: flex;
			    padding: 10px;
			    align-items: center;
			    justify-content: space-between;
			    border-bottom: 1px solid var(--xiao-modal-border-color);
				.modal-title {
				    color: var(--xiao-theme-color);
				    font-size: 13px;
				}
				.modal-close-icon {
				    cursor: pointer;
				    fill: var(--xiao-theme-color);
				    transition: .3s;
				}
				.modal-close-icon:hover {
				    fill: var(--xiao-theme-hover)
				}
			}
			.modal-footer {
			    width: 100%;
			    padding: 10px;
			    display: flex;
			    align-items: center;
			    justify-content: flex-end;
			    border-top: 1px solid var(--xiao-modal-border-color);
				box-sizing: border-box;
				.modal-btns {
				    width: 132px;
				    display: flex;
				    justify-content: space-between;
					.modal-close {
					    cursor: pointer;
					    color: var(--xiao-modal-close-color);
					    width: 60px;
					    height: 28px;
					    font-size: 12px;
					    transition: .3s;
					    border-radius: 4px;
					    background-color: var(--xiao-modal-bg-color);
					    border: 1px solid var(--xiao-modal-close-border-color);
					}
					.modal-close:hover {
					    opacity: .8;
					}
					.modal-ok {
					    cursor: pointer;
					    width: 60px;
					    height: 28px;
					    font-size: 12px;
					    transition: .3s;
					    border-radius: 4px;
					}
					// .modal-ok:hover {
					//     border: 1px solid var(--xiao-button-primary-bg-color-hover);
					//     background-color: var(--xiao-button-primary-bg-color-hover)
					// }
				}
			}
		}
		.modal-mask {
		    z-index: 998;
		    position: fixed;
		    top: 0;
		    right: 0;
		    bottom: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    background-color: var(--xiao-modal-mask-bg-color);
			backdrop-filter: blur(.1rem);
		}
	}
	
	@media (max-width: 600px) {
	    .xiao-modal{
			.modal-content {
			    width:80%
			}
		}
	}
	
	@media (max-width: 450px) {
	    .xiao-modal{
			.modal-content {
			    width:90%
			}
		}
	}
	
</style>